import React from "react";
import Page from "../components/page";

const PageTemplate = (data) => {
  return (
    <Page title={data.pageContext.title} img={data.pageContext.img}>
      {data.pageContext.content}
    </Page>
  );
};

export default PageTemplate;
