import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Layout from "./layout";

const Container = styled.div`
  .page-thumbnail {
    width: 100%;
    margin: 0 0 3rem 0;
  }

  .content {
    font-family: Karla, sans-serif;
    color: #666;
    display: flex;
    flex-direction: column;
    padding: 0 6rem 6rem 6rem;
    display: flex;
    flex-direction: column;
    max-width: 72rem;

    .wp-block-code {
      font-size: 1.4rem;
      line-height: 1.8;
      margin: 0 0 3rem 0;

      code {
        padding: 2rem;
      }
    }

    > p {
      font-size: 1.8rem;
      line-height: 3rem;
      margin: 0 0 3rem 0;
      width: 100%;

      :first-of-type:first-letter {
        color: #1d1d1d;
        font-size: 7.2rem;
        font-family: "Crimson Text", serif;
        float: left;
        line-height: 0.8;
        margin-right: 1.5rem;
      }
    }

    ul,
    ol {
      font-size: 1.8rem;
      line-height: 3rem;
      margin: 0 0 3rem 0;
      width: 100%;
    }

    a {
      color: #d4a259;
    }

    .wp-block-table {
      width: 100%;
      margin: 0;
    }

    table {
      width: 100%;
      margin: 0 0 3rem 0;

      thead {
        color: #000000;
      }

      td,
      th {
        font-size: 1.6rem;
        line-height: 2.4rem;
        padding: 0.8rem 0.5rem;
        border-bottom: 1px solid #eee;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #1d1d1d;
      font-family: "Crimson Text", serif;
      font-weight: 600;
      line-height: 1.2;
      text-rendering: optimizeLegibility;
      width: 100%;
    }

    h1 {
      font-size: 4.8rem;
      margin: 3.6rem 0 1.8rem 0;
    }

    h2 {
      font-size: 3.6rem;
      margin: 3rem 0 1.5rem 0;
    }

    h3 {
      font-size: 3rem;
      margin: 3rem 0 1.5rem 0;
    }

    h4 {
      font-size: 2.4rem;
      margin: 3rem 0 1.5rem 0;
    }

    blockquote {
      margin: 0 -16rem 3rem -16rem;
      text-align: center;

      p {
        color: #1d1d1d;
        font-size: 3.6rem;
        margin: 0;
        font-family: "Crimson Text", serif;
      }

      cite {
        color: #aaa;
        font-size: 1.8rem;
        line-height: 3rem;
        margin-top: 1.8rem;
        display: block;
      }

      &:before {
        color: #d4a259;
        content: "\\201C";
        display: block;
        font-size: 7.2rem;
        line-height: 2rem;
      }
    }

    .wp-block-code {
      width: 100%;
    }
  }
`;

const Page = (props) => {
  const content =
    typeof props.children === "string" ? (
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: props.children }}
      />
    ) : (
      props.children
    );

  return (
    <Layout title={props.title} image={props.img}>
      <Container>{content}</Container>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
    </Layout>
  );
};

export default Page;
